<template>
  <div class="Setting">
    <div v-show="showSetting" class="setting-fixed-bottom element-setting">
      <div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{$t("settings")}}</h5>
            <p class="card-text">{{$t("settingsChangePlateform")}}</p>
            <a :href=" url_insert + location" class="btn btn-primary round">{{$t("settingsDemoTeam")}}</a>
            <a :href=" url_other + location" class="btn btn-primary round ml-3">{{$t("settingsOtherTeam")}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="setting-fixed-bottom">
      <div class="container-setting" @click="getSetting()">
          <i class="fas fa-cogs"></i>
      </div>
    </div>
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');

export default {
  name : 'Setting',
  props: {
    location: String,
  },
  data: function() {
    return{
      showSetting: false,
      url: utils.getConfig().url,
      url_insert: utils.getConfig().url_insert,
      url_other: utils.getConfig().url_other,
    }
  },
  methods: {
    getSetting(){
      if(this.showSetting)
        this.showSetting = false;
      else
        this.showSetting = true;
    }
  },
}
</script>