<template>
  <div class="listCategoryProduct">
    <div v-if="!isEmpty" class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{parent.name[$i18n.locale]}}</h1>
    </div>

    <div class="container-fluid container-mobile-content pt-5 pr-3 pl-3">
      <div v-if="isEmpty" class="row col-12 d-flex justify-content-center">
        <h1 class="text-center title-mobile pt-5">{{$t('categoryNoProduct')}}</h1>
      </div>
      <div class="row pr-5 pl-5 d-flex justify-content-center">
        <div class="card ml-5 mr-5 mb-5" v-for="(product, id) in products" :key="id" style="width: 20rem;">
          <a class="card-edit" :id="'p' + product.id" :href="'/product/edit/' + product.id">
            <i class="fas fa-pencil-alt"></i>
          </a>
          <b-tooltip :target="'p' + product.id" triggers="hover">
            <span>{{$t('edit')}}</span>
          </b-tooltip>
          <img class="card-img-top category-img" :src="url + 'catalog/product/image/' + product.image" alt="Card image cap">
          <div class="card-body">
            <div class="row card-category-content">
              <div class="col-12 align-items-center">
                <div>
                  <h5 class="card-title">{{product.name[$i18n.locale]}}</h5>
                </div>
                <div>
                  <h5 class="card-id">{{$t('categoryIDLabel')}} : {{product.id}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="back-fixed-bottom">
      <a v-if="!isEmpty" class="container-back" :href="'/category/list/child/' + parent.parentCategory">
          <i class="fas fa-arrow-left"></i>
      </a>
      <a v-if="isEmpty" class="container-back" :href="'/category/list'">
          <i class="fas fa-arrow-left"></i>
      </a>
    </div>
    <!-- <Setting :location="'category/list/child/product/' + parent.id" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import Setting from '@/components/Settings.vue';

export default {
  name : 'ListCategoryProduct',
  components:{
    Setting,
  },
  data() {
    return {
      parent: {
        id: null,
        parentCategory: null,
        name: {fr: '', en: ''},
      },
      product: {
        name: {fr: '', en: ''},
        category: undefined,
        image: null,
      },
      products: [],
      categories: [],
      isEmpty: false,
      url: utils.getConfig().URL,
    }
  },
  mounted() {
    this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
    .then((response) => {
      this.categories = response.data;

      if(this.$route.params.id != undefined){

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.$http.get(utils.getConfig().URL + 'catalog/product/get/all/category/' + this.$route.params.id)
        .then((response) => {
          if(response.data){
            if(response.data.length == 0){
              this.isEmpty = true;
            }
            else{
              this.products = response.data;
              this.parent.id = this.products[0].category.id;
              this.parent.parentCategory = this.products[0].category.parentCategory;
              this.parent.name = this.products[0].category.name;
            }
            loader.hide();
          }
          else{
            window.location = "/category/list";
          }
        });
      }
    });
  }
}
</script>


